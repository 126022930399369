import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"amp-module-page"},[(_vm.pressRelease.background_distribution_result)?_c('amp-distribution-result',{staticClass:"mb-8",attrs:{"result":_vm.pressRelease.background_distribution_result}}):_vm._e(),_c('a-form',{ref:"form",attrs:{"auto-submit":!_vm.isModuleBusy},on:{"auto-submit":_vm.autoSave}},[_c('amp-row-first',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_vm._l((_vm.publishIssues),function(issue,i){return _c('a-alert',{key:i,attrs:{"message":issue.message}})}),_c('a-text-input',{ref:"headline",attrs:{"label":"Headline","rules":"required|max:80","rows":"1","textarea":"","auto-grow":"","observed":""},model:{value:(_vm.pressRelease.headline),callback:function ($$v) {_vm.$set(_vm.pressRelease, "headline", $$v)},expression:"pressRelease.headline"}})]},proxy:true},{key:"validation",fn:function(){return [_c('amp-validation',{attrs:{"input":_vm.$refs.headline}},[_c('a-alert',{attrs:{"type":_vm.pressRelease.headline ? 'success' : 'info'}},[_c('span',[_vm._v(" Headline must be fewer than 80 characters. Current: "+_vm._s(_vm.pressRelease.headline ? _vm.characterCount(_vm.pressRelease.headline) : 0)+" ")])])],1)]},proxy:true}])}),_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('a-text-input',{ref:"summary",attrs:{"label":"Summary","rules":"required|words:0,45","textarea":"","rows":"4","auto-grow":"","observed":""},model:{value:(_vm.pressRelease.summary),callback:function ($$v) {_vm.$set(_vm.pressRelease, "summary", $$v)},expression:"pressRelease.summary"}})]},proxy:true},{key:"validation",fn:function(){return [_c('amp-validation',{attrs:{"input":_vm.$refs.summary}},[_c('a-alert',{attrs:{"type":_vm.pressRelease.summary ? 'success' : 'info',"message":_vm.actualWordsCount(
                                _vm.pressRelease.summary,
                                0,
                                45,
                                'Summary'
                            )}})],1)]},proxy:true}])}),_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mb-1"},[_vm._v("Media")]),_c('div',{staticClass:"pb-8"},[_c('media-preview-list',{staticClass:"pa-1",attrs:{"media-resources":_vm.pressRelease.media_resources,"user-id":_vm.pressRelease.user_id,"module-title":"News Article"},on:{"mediaFiles":_vm.onMediaFilesUpdate}}),_c('validation-provider',{ref:"media_resources",attrs:{"name":"media_resources","slim":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pressRelease.media_resources),expression:"pressRelease.media_resources"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.pressRelease.media_resources)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pressRelease, "media_resources", $event.target.value)}}})])],1)]},proxy:true}])}),_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('a-content-editor',{attrs:{"label":"Content","rules":"required"},model:{value:(_vm.pressRelease.content),callback:function ($$v) {_vm.$set(_vm.pressRelease, "content", $$v)},expression:"pressRelease.content"}})]},proxy:true},{key:"validation",fn:function(){return [_c('content-editor-validations',{attrs:{"content":_vm.pressRelease.content,"rules":"wordCountBetween:300,1000|linkCount:html|nonFirstPerson"}})]},proxy:true}])}),_c('amp-row-last',{scopedSlots:_vm._u([(_vm.canSeeSupportUrls)?{key:"input",fn:function(){return [_c('div',[_vm._v("Supporting URLs")]),_vm._l((2),function(i){return _c('a-text-input',{key:i,attrs:{"rules":"url"},model:{value:(_vm.supportingUrlLinks[i - 1]),callback:function ($$v) {_vm.$set(_vm.supportingUrlLinks, i - 1, $$v)},expression:"supportingUrlLinks[i - 1]"}})})]},proxy:true}:null],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }