<template>
    <a-alert v-if="rejectionError" :message="rejectionError" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { AAlert } from '@/components/AAlert';

type DistributionResult = {
    rejected: boolean;
    rejectionError: string | null;
};

const STOP_WORDS: Array<RegExp | string> = ['API', 'credits'];

const DEFAULT_REJECTION_MESSAGE =
    'We were not able to publish your News Article. Please contact our support team.';

const AmpDistributionResultProps = Vue.extend({
    name: 'AmpDistributionResult',
    props: {
        result: {
            type: String,
            default() {
                return '{}';
            }
        }
    }
});

@Component({
    components: { AAlert },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor', 'isAdmin'])
    }
})
export default class AmpDistributionResult extends AmpDistributionResultProps {
    isAdmin!: boolean;
    isAuthor!: boolean;
    isEditor!: boolean;

    get rejectionError() {
        let distributionResult: DistributionResult = {
            rejected: false,
            rejectionError: null
        };

        if (this.result) {
            try {
                distributionResult = JSON.parse(this.result);
            } catch {
                /* silently */
            }

            if (distributionResult.rejected) {
                if (
                    !this.canSeeInternalError &&
                    this.hasInternalError(distributionResult.rejectionError)
                ) {
                    return DEFAULT_REJECTION_MESSAGE;
                }

                return distributionResult.rejectionError;
            }
        }

        return null;
    }

    get canSeeInternalError() {
        return this.isAdmin || this.isEditor || this.isAuthor;
    }

    hasInternalError(errorDescription?: string | null) {
        if (errorDescription) {
            return STOP_WORDS.some(w =>
                errorDescription?.match(new RegExp(`${w}`, 'g'))
            );
        }

        return false;
    }
}
</script>
